const UtilsFunction = {
  formatPrice(price) {
    if (price === 0) return 0
    price = price / 100
    const round = (value, precision) => {
      var multiplier = Math.pow(10, precision || 0)
      return Math.round(value * multiplier) / multiplier
    }
    return round(price, 7)
  },
  formatLanguages(value, languages) {
    if (!value || !languages) return ''
    let name
    languages.forEach(element => {
      if (element.code === value) {
        name = element.name
      }
    })
    return name
  },
  formatCode(value, languages) {
    if (!value || !languages) return ''
    let code
    languages.forEach(element => {
      if (element.name === value) {
        code = element.code
      }
    })
    return code
  },
  capitalize(value) {
    if (!value) return ''
    return value.toUpperCase()
  },
  capitalizeFirstLetter(value) {
    if (!value) return ''
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
}

export default UtilsFunction
