<template>
  <div>
    <ModalTermsConditions
      :modal-type="modalType"
      :general-content="generalContent"
      :marketing-content="marketingContent"
      :show-modal="showModal"
      @closeModal="showModal = false"
    />
    <BaseGrid>
      <BaseColumn>
        <LogoHeader
          class="mt-6"
          :title="$t('shared.text.create_your_client_account')"
        />
        <transition-group name="fade">
          <UsageProfile
            v-if="show.profile"
            :user-info="user"
            @show="showView"
            @openModal="openModal"
          />
          <UsageAccount
            v-if="show.account"
            :user-info="user"
            @show="showView"
          />
        </transition-group>
      </BaseColumn>
      <BaseColumnInfo :loading="loading">
        <div class="w-full h-full flex items-center justify-center">
          <img
            class="w-full h-auto object-cover max-w-[800px]"
            src="@/assets/images/g2_winter_platform2.png"
            @load="loading = false"
          />
        </div>
      </BaseColumnInfo>
    </BaseGrid>
  </div>
</template>
<script setup>
import ApiService from '@/api/new/services/api.service'
import { TokenService } from '@/api/new/services/storage.service'
import BaseColumn from '@/components/shared/BaseColumn'
import BaseColumnInfo from '@/components/shared/BaseColumnInfo'
import BaseGrid from '@/components/shared/BaseGrid'
import LogoHeader from '@/components/shared/LogoHeader'
import ModalTermsConditions from '@/components/shared/modals/ModalTermsConditions'
import UsageAccount from '@/components/usage/UsageAccount'
import UsageProfile from '@/components/usage/UsageProfile'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import _ from 'underscore'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const modalType = ref(null)
const showModal = ref(false)
const loading = ref(true)
const user = ref({ profile: {}, account: {} })
const show = ref({
  profile: true,
  account: false
})

const generalTerms = computed(() => store.state.newUserTerms.generalTerms)
const marketingTerms = computed(() => store.state.newUserTerms.marketingTerms)

const generalContent = computed(() => {
  return generalTerms.value.attributes && generalTerms.value.attributes.content
})
const marketingContent = computed(() => {
  return (
    marketingTerms.value.attributes && marketingTerms.value.attributes.content
  )
})

onMounted(() => {
  TokenService.removeAll()
  getAllTerms()
  $cookies.set('env', process.env.VUE_APP_ENV)
  ApiService.init(process.env.VUE_APP_ROOT_API)
  stopLoader()
})

const stopLoader = () => store.dispatch('loading/stopLoader')
const getAllTerms = () => store.dispatch('newUserTerms/getAllTerms')

function showView(view) {
  if (view.profile) user.value.profile = view.profile
  if (view.account) user.value.account = view.account
  _.mapObject(show.value, (val, key) => {
    if (key === view.nextView) {
      show.value[key] = true
    } else {
      show.value[key] = false
    }
  })
}
function openModal(type) {
  modalType.value = type
  showModal.value = true
}
</script>
