<template>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script setup>
import { $cookies } from '@/main'
import moment from 'moment'
import { onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const { locale } = useI18n()
const router = useRouter()
const route = useRoute()

onMounted(() => {
  setLocaleAndRedirect()
})

function setLocaleAndRedirect() {
  const storedLocale = $cookies.get('locale') || 'en'
  locale.value = storedLocale
  moment.locale(storedLocale)
  $cookies.set('locale', storedLocale)

  let newPath = {
    path: `/${storedLocale}${route.path}`,
    query: route.query
  }

  if (route.query.team) {
    newPath.query.team = route.query.team
  }

  if (
    route.params.locale !== storedLocale ||
    (route.query.team && newPath.query.team !== route.query.team)
  ) {
    router.replace(newPath)
  }
}
watch(
  () => route.path,
  () => {
    setLocaleAndRedirect()
  }
)
</script>
