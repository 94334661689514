import mitt from 'mitt'
import { onBeforeUnmount } from 'vue'

const emitter = mitt()

export default function useEmitter() {
  const eventHandlers = []

  onBeforeUnmount(() =>
    eventHandlers.forEach((eventHandler) =>
      emitter.off(eventHandler.event, eventHandler.handler)
    )
  )

  return {
    on(event, handler) {
      eventHandlers.push({ event, handler })
      emitter.on(event, handler)
    },
    emit(event, payload) {
      emitter.emit(event, payload)
    }
  }
}
